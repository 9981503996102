import React from "react";
import styled from "styled-components";
import { navigate } from "gatsby";
import Helmet from "react-helmet";
import { connect } from "react-redux";

import ReactPlayer from "react-player";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Modal from "@material-ui/core/Modal";

import { THEME, COLORS } from "../utils/theme";
import siteConfig from "../../data/SiteConfig";
import * as actions from "../actions/index";
import {
  PERFORMER_TYPE_SLUG_MAP,
  PERFORMER_TYPE_PAGENAME_MAP
} from "../constants/constants";
import {
  getShortSiteTitle,
  getThirdPartySiteIcon,
  prettyPrintHtmlEntitiesInString,
  useStaticDomain
} from "../utils/strings";

import Page from "../components/Layout/Page";
import { FixedWidthContainer } from "../components/Layout/ContentContainers";
import { StyledComponentsBreakpoints } from "../components/Layout/Responsive";

import Button from "../components/Forms/Button";
import { BackupSharp } from "@material-ui/icons";

const iconColor = COLORS.blue.hex;

class PerformerTemplate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  getMetaUrl() {
    const { location } = this.props;

    return `${siteConfig.siteUrl}${location.pathname}` || siteConfig.siteUrl;
  }

  getMetaTitle() {
    const { pathContext } = this.props;
    const { performer } = pathContext;

    let pageTitle = siteConfig.siteTitle;

    pageTitle = `${prettyPrintHtmlEntitiesInString(
      performer.title.rendered
    )} | ${PERFORMER_TYPE_PAGENAME_MAP[performer.acf.type]} | ${pageTitle}`;
    return pageTitle;
  }

  openModal() {
    this.setState({
      modalOpen: true
    });
  }

  closeModal() {
    this.setState({
      modalOpen: false
    });
  }

  render() {
    const { pathContext } = this.props;
    const { performer } = pathContext;
    const { modalOpen } = this.state;

    return (
      <Page
        title={prettyPrintHtmlEntitiesInString(performer.title.rendered)}
        showTitle={false}
      >
        <Helmet title={this.getMetaTitle()}>
          <meta property="og:url" content={this.getMetaUrl()} />
          <meta property="og:title" content={this.getMetaTitle()} />
        </Helmet>
        <FixedWidthContainer style={{ marginBottom: "6rem" }}>
          <Button
            onClick={() =>
              navigate(`/${PERFORMER_TYPE_SLUG_MAP[performer.acf.type]}`)}
            style={{ marginBottom: "18px" }}
          >
            {`← Back to ${PERFORMER_TYPE_PAGENAME_MAP[performer.acf.type]}`}
          </Button>

          <PerformerContainer>
            <PerformerInfoContainer>
              {performer &&
                performer.acf &&
                performer.acf.photo &&
                performer.acf.photo.sizes &&
                performer.acf.photo.sizes.medium && (
                  <PerformerImageContainer>
                    <img
                      src={useStaticDomain(performer.acf.photo.sizes.medium)}
                      alt={prettyPrintHtmlEntitiesInString(
                        performer.title.rendered
                      )}
                      title={prettyPrintHtmlEntitiesInString(
                        performer.title.rendered
                      )}
                      onClick={this.openModal}
                    />
                    <Modal
                      aria-labelledby={prettyPrintHtmlEntitiesInString(
                        performer.title.rendered
                      )}
                      aria-describedby={prettyPrintHtmlEntitiesInString(
                        performer.title.rendered
                      )}
                      open={modalOpen}
                      onClose={this.closeModal}
                    >
                      <ModalImageWrapper>
                        <ModalImageContainer>
                          <img
                            src={useStaticDomain(
                              performer.acf.photo.sizes.large
                            )}
                            alt={prettyPrintHtmlEntitiesInString(
                              performer.title.rendered
                            )}
                            title={prettyPrintHtmlEntitiesInString(
                              performer.title.rendered
                            )}
                          />
                        </ModalImageContainer>
                      </ModalImageWrapper>
                    </Modal>
                  </PerformerImageContainer>
                )}
              <PerformerDetailsContainer>
                <PerformerName>
                  {prettyPrintHtmlEntitiesInString(performer.title.rendered)}
                </PerformerName>
                {performer.acf.hometown && (
                  <PerformerHometown>
                    {performer.acf.hometown}
                  </PerformerHometown>
                )}
                {performer.acf.record_label && (
                  <PerformerRecordLabel>
                    {performer.acf.record_label}
                  </PerformerRecordLabel>
                )}
                {performer.acf.affiliated_promotions && (
                  <PerformerAffiliatedPromotions>
                    {performer.acf.affiliated_promotions}
                  </PerformerAffiliatedPromotions>
                )}
              </PerformerDetailsContainer>

              <PerformerLinks>
                {performer.acf.link_twitter && (
                  <li>
                    <PerformerLink>
                      <LinkIconContainer>
                        <FontAwesomeIcon
                          icon={["fab", "twitter"]}
                          color={iconColor}
                        />
                      </LinkIconContainer>
                      <LinkTextContainer>
                        <a
                          rel="nofollow"
                          href={performer.acf.link_twitter.url}
                          target={performer.acf.link_twitter.target}
                        >
                          Twitter
                        </a>
                      </LinkTextContainer>
                    </PerformerLink>
                  </li>
                )}
                {performer.acf.link_instagram && (
                  <li>
                    <PerformerLink>
                      <LinkIconContainer>
                        <FontAwesomeIcon
                          icon={["fab", "instagram"]}
                          color={iconColor}
                        />
                      </LinkIconContainer>
                      <LinkTextContainer>
                        <a
                          rel="nofollow"
                          href={performer.acf.link_instagram.url}
                          target={performer.acf.link_instagram.target}
                        >
                          Instagram
                        </a>
                      </LinkTextContainer>
                    </PerformerLink>
                  </li>
                )}
                {performer.acf.link_facebook && (
                  <li>
                    <PerformerLink>
                      <LinkIconContainer>
                        <FontAwesomeIcon
                          icon={["fab", "facebook"]}
                          color={iconColor}
                        />
                      </LinkIconContainer>
                      <LinkTextContainer>
                        <a
                          rel="nofollow"
                          href={performer.acf.link_facebook.url}
                          target={performer.acf.link_facebook.target}
                        >
                          Facebook
                        </a>
                      </LinkTextContainer>
                    </PerformerLink>
                  </li>
                )}
                {performer.acf.link_bandcamp && (
                  <li>
                    <PerformerLink>
                      <LinkIconContainer>
                        <FontAwesomeIcon
                          icon={["fab", "bandcamp"]}
                          color={iconColor}
                        />
                      </LinkIconContainer>
                      <LinkTextContainer>
                        <a
                          rel="nofollow"
                          href={performer.acf.link_bandcamp.url}
                          target={performer.acf.link_bandcamp.target}
                        >
                          Bandcamp
                        </a>
                      </LinkTextContainer>
                    </PerformerLink>
                  </li>
                )}
                {performer.acf.link_spotify && (
                  <li>
                    <PerformerLink>
                      <LinkIconContainer>
                        <FontAwesomeIcon
                          icon={["fab", "spotify"]}
                          color={iconColor}
                        />
                      </LinkIconContainer>
                      <LinkTextContainer>
                        <a
                          rel="nofollow"
                          href={performer.acf.link_spotify.url}
                          target={performer.acf.link_spotify.target}
                        >
                          Spotify
                        </a>
                        {" "}
                      </LinkTextContainer>
                    </PerformerLink>
                  </li>
                )}
                {performer.acf.link_custom_1 && (
                  <li>
                    <PerformerLink>
                      <LinkIconContainer>
                        <FontAwesomeIcon
                          icon={getThirdPartySiteIcon(
                            performer.acf.link_custom_1.url
                          )}
                          color={iconColor}
                        />
                      </LinkIconContainer>
                      <LinkTextContainer>
                        <a
                          rel="nofollow"
                          href={performer.acf.link_custom_1.url}
                          target={performer.acf.link_custom_1.target}
                        >
                          {performer.acf.link_custom_1.title
                            ? performer.acf.link_custom_1.title
                            : getShortSiteTitle(
                                performer.acf.link_custom_1.url
                              )}
                        </a>
                      </LinkTextContainer>
                    </PerformerLink>
                  </li>
                )}
                {performer.acf.link_custom_2 && (
                  <li>
                    <PerformerLink>
                      <LinkIconContainer>
                        <FontAwesomeIcon
                          icon={getThirdPartySiteIcon(
                            performer.acf.link_custom_2.url
                          )}
                          color={iconColor}
                        />
                      </LinkIconContainer>
                      <LinkTextContainer>
                        <a
                          rel="nofollow"
                          href={performer.acf.link_custom_2.url}
                          target={performer.acf.link_custom_2.target}
                        >
                          {performer.acf.link_custom_2.title
                            ? performer.acf.link_custom_2.title
                            : getShortSiteTitle(
                                performer.acf.link_custom_2.url
                              )}
                        </a>
                      </LinkTextContainer>
                    </PerformerLink>
                  </li>
                )}
                {performer.acf.link_custom_3 && (
                  <li>
                    <PerformerLink>
                      <LinkIconContainer>
                        <FontAwesomeIcon
                          icon={getThirdPartySiteIcon(
                            performer.acf.link_custom_3.url
                          )}
                          color={iconColor}
                        />
                      </LinkIconContainer>
                      <LinkTextContainer>
                        <a
                          rel="nofollow"
                          href={performer.acf.link_custom_3.url}
                          target={performer.acf.link_custom_3.target}
                        >
                          {performer.acf.link_custom_3.title
                            ? performer.acf.link_custom_3.title
                            : getShortSiteTitle(
                                performer.acf.link_custom_3.url
                              )}
                        </a>
                      </LinkTextContainer>
                    </PerformerLink>
                  </li>
                )}
              </PerformerLinks>

              {performer.acf.mp3 && performer.acf.mp3.url && (
                <PerformerMediaContainer>
                  {performer.acf.mp3.url && (
                    <Mp3PlayerWrapper>
                      <Button
                        onClick={() =>
                          this.props.dispatch(
                            actions.playTrackByTrackId(performer.acf.mp3.id)
                          )}
                      >
                        <FontAwesomeIcon
                          icon={["fa", "play"]}
                          color={iconColor}
                        />
                        {` Listen to “${prettyPrintHtmlEntitiesInString(
                        performer.title.rendered
                      )} - 
                      ${prettyPrintHtmlEntitiesInString(performer.acf.mp3_song_name)}”`}
                      </Button>
                    </Mp3PlayerWrapper>
                  )}
                </PerformerMediaContainer>
              )}
            </PerformerInfoContainer>
            <PerformerBioContainer>
              {performer.content.rendered && (
                <div>
                  <MediaHeadline>Bio</MediaHeadline>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: performer.content.rendered,
                    }}
                  />
                </div>
              )}
              {performer.acf.whats_your_favourite_animal && (
                <p>
                  <strong>WHAT&rsquo;S YOUR FAVO(U)RITE ANIMAL?</strong>
                  <br />
                  {performer.acf.whats_your_favourite_animal}
                </p>
              )}
              {performer.acf.whats_your_favourite_scary_movie && (
                <p>
                  <strong>WHAT&rsquo;S YOUR FAVO(U)RITE SCARY MOVIE?</strong>
                  <br />
                  {performer.acf.whats_your_favourite_scary_movie}
                </p>
              )}
              {performer.acf.which_fest_sets_are_you_most_excited_for && (
                <p>
                  <strong>WHICH FEST SET ARE YOU MOST EXCITED FOR?</strong>
                  <br />
                  {performer.acf.which_fest_sets_are_you_most_excited_for}
                </p>
              )}

              {(performer.acf.youtube_link || performer.acf.vimeo_link) && (
                <div>
                  {(performer.content.rendered ||
                    performer.acf.superpower ||
                    performer.acf.band_most_excited_for) && (
                    <VideoContentSeparator />
                  )}
                  <VideoContentContainer>
                    {performer.acf.youtube_link && (
                      <div>
                        <MediaHeadline>YouTube</MediaHeadline>
                        <ResponsiveVideoPlayerWrapper>
                          <ReactPlayer
                            className="react-player"
                            url={performer.acf.youtube_link.replace(
                              "http://",
                              "https://"
                            )}
                            width="100%"
                            height="100%"
                          />
                        </ResponsiveVideoPlayerWrapper>
                      </div>
                    )}

                    {performer.acf.vimeo_link && (
                      <div>
                        <MediaHeadline>Vimeo</MediaHeadline>
                        <ResponsiveVideoPlayerWrapper>
                          <ReactPlayer
                            className="react-player"
                            url={performer.acf.vimeo_link.replace(
                              "http://",
                              "https://"
                            )}
                            width="100%"
                            height="100%"
                            controls="true"
                          />
                        </ResponsiveVideoPlayerWrapper>
                      </div>
                    )}
                  </VideoContentContainer>
                </div>
              )}
            </PerformerBioContainer>
          </PerformerContainer>
        </FixedWidthContainer>
      </Page>
    );
  }
}

export default connect()(PerformerTemplate);

const PerformerContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  hr {
    width: 66%;
    margin: 2rem auto 1rem;
  }

  ${StyledComponentsBreakpoints.Phone` 
    hr {
      display: none;
    }
  `};
`;

const PerformerInfoContainer = styled.div`
  width: 360px;
  order: 1;
  flex: 0 1 360px;

  a {
    display: inline-block;
  }

  @media (max-width: 900px) {
    flex: 0 1 40%;
  }
  ${StyledComponentsBreakpoints.Phone`
    width: 100%;
    flex: 0 1 100%;
  `};
`;
const PerformerLinks = styled.ul`
  list-style: none;
  li {
    margin: 0;
  }
`;
const PerformerLink = styled.div`
  display: flex;
  align-items: center;
  margin: 8px 0 2px;
`;
const LinkIconContainer = styled.div`
  flex: 0 0 2.4rem;
  text-align: center;
  min-width: 2.4rem;
  width: 2.4rem;

  svg,
  .svg-inline--fa.fa-w-16,
  .svg-inline--fa.fa-w-14 {
    margin-right: 0.666rem;
    height: 1.2rem;
    width: 1.2rem;
  }
`;
const LinkTextContainer = styled.h6`
  margin-top: -0px;
  a {
  color: ${COLORS.white.rgba};
  line-height: 24px;
  font-size: 13px;
  letter-spacing: 0.4px;
    text-decoration: none;
  }
`;

const PerformerBioContainer = styled.div`
  order: 2;
  flex: 1 1 50%;

  margin-left: 2rem;
  margin-bottom: 16px;
  
  * {
    font-size: 15px;
    line-height: 24px;
  }

  @media (max-width: 768px) {
    flex: 1 1 40%;
  }
  ${StyledComponentsBreakpoints.Phone`
    margin-top: 0;
    margin-left: 0;
  `};
`;

const PerformerImageContainer = styled.div`
  margin-bottom: 1rem;

  > img {
    max-width: 100%;
    cursor: pointer;

    ${StyledComponentsBreakpoints.Phone`
        width: 100%;
    `};
  }
`;
const ModalImageWrapper = styled.div`
  z-index: 100000000;
  width: 100%;
  height: 100%;
  max-width: 900px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  outline: none;
  pointer-events: none;
`;
const ModalImageContainer = styled.div`
  z-index: 100000000;
  flex: auto;
  position: relative;

  img {
    max-width: 98%;
  }
`;
const PerformerDetailsContainer = styled.div`
  margin-bottom: 1rem;
`;

const PerformerName = styled.h1`
  font-size: 32px;
`;
const PerformerHometown = styled.h3`
  margin-top: -2px;
  font-size: 17px;
  opacity: 0.666;
`;
const PerformerRecordLabel = styled.h3`
  font-size: 20px;
  margin-top: 16px;
  margin-bottom: 24px;
`;
const PerformerAffiliatedPromotions = styled.h3`
  font-size: 28px;
  margin-top: 16px;
  margin-bottom: 30px;
`;

const PerformerMediaContainer = styled.div`
  text-align: center;
`;

const MediaHeadline = styled.h5`
  text-align: left;
  font-size: 20px;
  margin-bottom: 5px;
`;

const Mp3PlayerWrapper = styled.div`
  margin-bottom: 2rem;

  font-size: 0.8rem;
  svg {
    margin-right: 8px;
  }
`;

const VideoContentSeparator = styled.hr`
  margin-bottom: 2rem;
  opacity: 0;
`;
const VideoContentContainer = styled.div``;

const ResponsiveVideoPlayerWrapper = styled.div`
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */

  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }
`;
